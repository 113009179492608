<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between w-100">
                <div class="d-flex flex-column">
                    <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="secondary--text font-weight-bold">Course Users / Instances</span>
                    <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="secondary--text font-weight-bold">Project Users / Instances</span>
                    <span v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="secondary--text font-weight-bold">Dataset Users / Instances</span>

                    <span v-if="tab === tabValues.SPACE_MEMBERS_TAB && fetchingSpaceUsers" class="caption">Fetching users data .....</span>
                    <span v-if="tab === tabValues.SPACE_INSTANCES_TAB && fetchingInstances" class="caption">Fetching instances data .....</span>
                    <span v-if="tab === tabValues.SPACE_INVITATIONS_TAB && fetchingSpaceInvitations" class="caption">Fetching invitations data .....</span>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-card class="mt-5 overflow-hidden d-flex">
                <v-tabs v-model="tab">
                    <v-tab v-for="tab in tabs" :key="tab.tabName">
                        <v-icon class="mr-1" small>{{ tab.icon }}</v-icon>
                        {{ tab.tabName }}
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-tab>
                        <v-icon color="primary" class="mr-1" small>add</v-icon>
                        <span class="secondary--text font-weight-bold">invite</span>
                    </v-tab>
                </v-tabs>
                <add-instance-dialog @inviteUsers="inviteToInstance($event.instanceName)">
                    <v-btn text tile class="py-6">
                        <v-icon color="primary" class="mr-1" small>add</v-icon>
                        <span class="secondary--text font-weight-bold">Add New Instance</span>
                    </v-btn>
                </add-instance-dialog>
            </v-card>

            <v-tabs-items class="mt-7" v-model="tab">
                <v-tab-item v-for="tab in tabs" :key="tab.tabName">
                    <component
                        @instanceData="setDataForExistingInstanceToInviteTo($event.data)"
                        @goToInvite="switchTab($event.value, 'invite')"
                        :is="tab.component"></component>
                </v-tab-item>
                <v-tab-item>
                    <component :dataForInstanceToInviteTo="dataForInstanceToInviteTo" :is="'TheSpaceInvite'"></component>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const TheSpaceUsers = () => import('../components/TheSpaceUsers')
const TheSpaceInvitations = () => import('../components/TheSpaceInvitations')
const TheSpaceInstances = () => import('../components/TheSpaceInstances')
const TheSpaceInvite = () => import('../components/TheSpaceInvite')
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog.vue')

export default {
    components: { TheSpaceUsers, TheSpaceInvitations, TheSpaceInstances, TheSpaceInvite, AddInstanceDialog },
    mixins: [enumsData],
    data() {
        return {
            tab: null,
            tabs: [
                { tabName: 'Members', icon: 'mdi-account-group-outline', component: 'TheSpaceUsers' },
                { tabName: 'Instances', icon: 'group', component: 'TheSpaceInstances' },
                { tabName: 'Invitations', icon: 'mail', component: 'TheSpaceInvitations' }
            ],
            dataForInstanceToInviteTo: null,
            tabValues: {
                SPACE_MEMBERS_TAB: 0,
                SPACE_INSTANCES_TAB: 1,
                SPACE_INVITATIONS_TAB: 2,
                SPACE_INVITE_TAB: 3
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['fetchingInstances', 'fetchingSpaceUsers', 'fetchingSpaceInvitations']),
        ...mapGetters('spaceStore', ['currentSpaceType'])
    },
    methods: {
        setDataForExistingInstanceToInviteTo: function (data) {
            this.dataForInstanceToInviteTo = data
            this.tab = this.tabValues.SPACE_INVITE_TAB
        },
        switchTab(confirmAction, tab) {
            if (confirmAction && tab === 'invite') {
                this.tab = this.tabValues.SPACE_INVITE_TAB
            } else if (confirmAction && tab === 'members') {
                this.tab = this.tabValues.SPACE_MEMBERS_TAB
            }
        }
    },
    mounted() {
        this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
        this.$store.dispatch('spaceStore/fetchSpaceInvitations', this.$route.params.sid)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from && from.name && from.name === 'snapshot-add-files-hint') {
                vm.tab = vm.tabValues.SPACE_INVITE_TAB
            }
        })
    }
}
</script>
